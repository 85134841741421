<template>
  <div>
    <template>
      <a-dropdown placement="bottomLeft">
        <a-button shape="round">
          <span style="display: inline-block; font-size: 14px">{{
            language
          }}</span>
        </a-button>
        <a-menu slot="overlay" @click="callSetLangAction">
          <a-menu-item key="en">
            <a-row :gutter="16">
              <a-col :span="8"><CountryFlag country="us" size="normal" /></a-col>
              <a-col :span="8" style="margin-top: 8px;"><span>English</span></a-col>
            </a-row>
          </a-menu-item>
          <a-menu-item key="la">
            <a-row :gutter="16">
              <a-col :span="8"><CountryFlag country="la" size="normal" /></a-col>
              <a-col :span="8" style="margin-top: 8px;"><span>ລາວ</span></a-col>
            </a-row>
          </a-menu-item
          >
        </a-menu>
      </a-dropdown>
    </template>
  </div>
</template>

<script>
//import { mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  name: "ChangeLanguage",
  components: {
    CountryFlag,
  },

  data() {
    return {
      language: this.$t("language"),
    };
  },
  methods: {
    callSetLangAction(event) {
      this.language = event.key === "la" ? "LA" : "EN";
      this.$store.dispatch("language/setLang", event.key);
    },
  },
};
</script>

<style lang="scss" scoped></style>
