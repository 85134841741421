import lscache from "lscache";

export const lswrite = (key, value, timeout) => {
    lscache.set(key, value, timeout)
    return true
}

export const lsread = (key)=> {
    return lscache.get(key)
}

export const lsremove = (key) => {
    lscache.remove(key)
}