import {sha512} from "js-sha512";
import moment from 'moment'

export const hashPassword = (text) => {
    const hash = sha512.create();
    hash.update(text);
    return hash.hex();
}

export const baseUrl =  process.env.NODE_ENV === "production" ? "https://uaisale.laospt.com" : "http://localhost:5000"

export const APIUrl = () => {
   return process.env.NODE_ENV === "production" ? "https://uaisale.laospt.com" : "http://localhost:5000"
}

export const uploadUrl = () => {
    return APIUrl() + '/upload'
}

export const imageUrl = process.env.NODE_ENV === "production" ? "https://uaisale.laospt.com/public" : "http://localhost:5000/public"
// export const imageUrl = () => {
//     return process.env.NODE_ENV === "production" ? "https://uaisale.laospt.com/public" : "http://localhost:5000/public"
// }

export const parseImageUrl=(img)=> {
    return img && !img.startsWith('http') ? `${imageUrl}/${img}` : img
  }

export const formatDate=(d)=> {
    return moment(d).format('YYYY-MM-DD')
  }
  export const statusTagColor = (status) => {
    if (status?.toUpperCase() === "ACTIVE") return "green";
    if (status?.toUpperCase() === "APPROVED") return "green";
    if (status?.toUpperCase() === "PAID") return "green";
    if (status?.toUpperCase() === "YES") return "green";
    if (status?.toUpperCase() === "PENDING") return "orange";
    if (status?.toUpperCase() === "WAITING") return "orange";
    if (status?.toUpperCase() === "NO") return "red";
    if (status?.toUpperCase() === "REJECTED") return "red";
    if (status?.toUpperCase() === "CANCELLED") return "red";
    if (status?.toUpperCase() === "DELETED") return "red";
    if (status?.toUpperCase() === "PROCESSING") return "blue";
    if (status?.toUpperCase() === "COMPLETED") return "green";
    return "grey"
}
  export const  formatMoney = (amount) => {
    if (!amount) amount = 0;
    if (typeof amount === 'string') {
        amount = parseFloat(amount)
    }
    const amt = amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    let symbol = '₭'
    return `${symbol} ${amt.split('LAK')[0].trim()}`;
}