import axios from "axios";
import { hashPassword, baseUrl } from '../utils/helper';
import { lswrite, lsremove } from '../utils/storage';

class AuthService {
  async login(user) {
    try {
      const response = await axios.post(baseUrl + "/login", {
        password: hashPassword(user.password),
        username: user.username,
        // type: user.type,
      });
  
      if (response.data) {
        const userData = {
          id: response.data.id,
          name: `${response.data.firstname} ${response.data.lastname}`,
          token: response.data.token,
          role: response.data.role,
          status: response.data.status,
        }
        lswrite('user',userData)
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  logout() {
    lsremove("user");
  }

  register(user) {
    return axios.post(baseUrl + "/register/confirm", user);
  }
}

export default new AuthService();
