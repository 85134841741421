import Vue from "vue";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import App from "./App.vue";
import DefaultLayout from "./layouts/Default.vue";
import DashboardLayout from "./layouts/Dashboard.vue";
import router from "./router";
import i18n from "./languages/i18n";
import store from "./store/index";
import VeeValidate from "vee-validate";
import { FormModel } from "ant-design-vue";
import VueTelInput from "vue-tel-input";
Vue.use(FormModel);

import "./scss/app.scss";

Vue.use(Antd);
Vue.use(VeeValidate);

Vue.config.productionTip = false;

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.use(VueTelInput);

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default app;
