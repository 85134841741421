<template>
  <!-- Layout Footer -->
  <a-layout-footer>
    <a-divider style="height: 2px; background-color: #7cb305" />
    <!-- Copyright Notice -->
    <p class="copyright">
      Copyright © 2022 - {{ year }} All rights reserved
      <a href="https://laostp.com.la">| Powered by LaoPT</a>
    </p>
    <!-- / Copyright Notice -->
  </a-layout-footer>
  <!-- / Layout Footer -->
</template>

<script>
export default {
  data() {
    return { year: new Date().getFullYear() };
  },
};
</script>

<style lang="scss" scoped>
.nav-link span {
  vertical-align: middle;
}
.ant-menu-submenu-popup {
  width: 100%;
}
</style>
