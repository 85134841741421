<template>
  <!-- Layout Header ( Navbar ) -->
  <div class="">
    <a-layout-header>
      <div class="header-col header-brand">
        <a-button
          type="link"
          @click="collapseNav = collapseNav ? 0 : 1"
          class="btn-menu-trigger"
        >
          <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            />
          </svg>
        </a-button>
      </div>
      <div class="header-col header-nav">
        <!-- Navigation Menu For Large Screens -->
        <a-menu mode="horizontal" class="menu-large">
          <a-menu-item>
            <router-link
              style="display: flex; align-items: center"
              to="/"
              class="nav-link"
              @click="(e) => e.preventDefault()"
            >
              <a-icon style="margin-right: 0px" type="login" />
              <span>{{ $t("button.login").toUpperCase() }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link
              style="display: flex; align-items: center"
              to="/sign-up"
              class="nav-link"
              @click="(e) => e.preventDefault()"
            >
              <a-icon style="margin-right: 0px" type="user-add" />
              <span>{{ $t("button.register").toUpperCase() }}</span>
            </router-link>
          </a-menu-item>
        </a-menu>
        <!-- / Navigation Menu For Large Screens -->

        <!-- Collapsible Navigation Menu For Small Screens -->
        <div class="menu-small">
          <!-- Collapsible Component For Navigation Menu For Small Screens -->
          <a-collapse v-model="collapseNav" accordion>
            <a-collapse-panel key="1">
              <!-- Navigation Menu For Small Screens -->
              <a-menu mode="vertical">
                <a-menu-item>
                  <router-link
                    to="/"
                    class="nav-link"
                    @click="(e) => e.preventDefault()"
                  >
                    <a-icon type="login" />
                    <span>{{ $t("button.login").toUpperCase() }}</span>
                  </router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link
                    to="/sign-up"
                    class="nav-link"
                    @click="(e) => e.preventDefault()"
                  >
                    <a-icon type="user-add" />
                    <span>{{ $t("button.register").toUpperCase() }}</span>
                  </router-link>
                </a-menu-item>
              </a-menu>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
      <div class="header-col header-btn">
        <ChangeLanguage />
      </div>
    </a-layout-header>
  </div>
  <!-- / Layout Header ( Navbar ) -->
</template>

<script>
import ChangeLanguage from "./ChangeLanguage.vue";
import CountryFlag from "vue-country-flag";
export default {
  name: "Dashboard",
  components: {
    ChangeLanguage,
    CountryFlag,
  },
  data() {
    return {
      // Collapse navigation value.
      // Binded model property for "Collapsible Navigation Menu" collapsed status .
      collapseNav: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed; /* fixing the position takes it out of html flow - knows
                   nothing about where to locate itself except by browser
                   coordinates */
  left: 0; /* top left corner should start at leftmost spot */
  top: 0; /* top left corner should start at topmost spot */
  width: 100vw; /* take up the full browser width */
  z-index: 200; /* high z index so other content scrolls underneath */
  height: 100px; /* define height for content */
  margin-bottom: 15px;
}
.nav-link svg {
  margin-right: 5px;
  vertical-align: middle;
}
.nav-link span {
  vertical-align: middle;
}
.ant-menu-submenu-popup {
  width: 100%;
}
.menu-large {
}
</style>
