export default {
  flag: "us",
  language: "EN",
  error: {
    pageTitle: "Error",
    401: "Your session has end, please login and try again.",
    500: "Internal server error, please wait.",
    404: "Sorry, the page you visited does not exist.",
    dataExist: "Data already exist",
    getImage: "Please select image",
  },
  action: {
    AddButton: "Add New",
    EditButton: "Edit",
    DeleteButton: "Delete",
    CancelButton: "Cancel",
    BackButton: "Back",
    viewButton: "View",
    MoreDetailButton: "More Detail",
    RetryPaymentButton: "Retry Payment",
    BackHomeButton: "Back to Home",
    Loading: "Loading...",
    PrintCertificate: "Print Certificate",
  },
  forgetPass: {
    description: "Use these forms to reset your password.",
    resetPass: "Reset password",
    resetPassWith: "Reset Password With",
  },
  form: {
    description:
      "Use these forms to login or create your account.",
    loginWith: "Login With",
    registerWith: "Register With",
    or: "Or",
    phone: "Phone",
    email: "Email",
    password: "Password",
    newPassword: "New Password",
    forgetPassword: "Forget password",
    confirmPassword: "Confirm Password",
    dontHaveAccount: "Don't have an account",
    haveAccount: "Already have an account",
    otp: "OTP",
    firstName: "First Name",
    lastName: "Last Name",
    gender: {
      title: "Gender",
      male: "Male",
      female: "Female",
      other: "Other",
    },
    birthday: "Date of Birth",
    passportNumber: "Passport Number",
    relationship: {
      title: "Relationship",
      couple: "Couple",
      children: "Children",
      parents: "Parents",
      friend: "Friend",
      other: "Other",
    },
    document: "Documents",
    passport: "Passport",
    visa: "Visa",
    other: "Other",
    iAgree: "I agree the",
    termCondition: "Terms and Conditions",
    makeClaimRequest: "Make a Claim Request",
    claimToMember: "Claim to Member",
    claimPurpose: "Claim Purpose",
    claimAmount: "Claim Amount",
    claimDate: "Date Time",
    hospitalAndService: "Hospital and Service",
    medicine: "Medicine",
    checkup: "Checkup",
    died: "Died",
    selectPerson: "Select a person",
    choosePurpose: "Please choose a purpose",
    chooseHospital: "Please choose a hospital",
    enterDescription: "Please enter the description",
    city: "City",
    address: "Address",
    purposeVisit: "Purpose of visit",
    stateProvince: "State/Province",
    country: "Country",
    paymentType: "Payment Type",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    cvv: "CVV",
    tour:"Tour",
    work: "Work",
    work30: "Work (Gather than 30 Days)"
  },
  label: {
    insuranceCategory: "Insurance Categories",
    insurancePackage: "Packages",
    listMember: "List of Member",
    includeMe: "Include me",
    payment: "Payment",
    search: "Search",
    price: "Price",
    single: "SINGLE",
    family: "FAMILY",
    product: "Product",
    type: "Type",
    status: "Status",
    createdDate: "Created Date",
    amount: "Amount",
    myInsuranceDetail: "My Insurance Detail",
    claimRequest: "Claim Request",
    requestAt: "Request At",
    claimId: "Claim ID",
    claimTo: "Claim To",
    claimDescription: "Description",
    claimDate: "Claim Date",
    noClaimRequest: "No Claim Request",
    noClaimAvailable: "There is no certificate",
    listHospital: "List of Hospital",
    listServiceUnit: "List of Service Unit",
    image: "Image",
    type: "Type",
    name: "Name",
    tel: "Tel",
    address: "Address",
    map: "Map",
    location: "Location",
    ancientMystery: "Ancient Mysteries",
    buddhismAndBelief: "Buddhism and Belief",
    theSecretWar: "The Secret War",
    wondersOfNature: "Wonders Of Nature",
    authenticCulture: "Authentic Culture",
    legendaryLandmarks: "Legendary Landmarks",
    profileInfo: "My Profile",
    paymentMethod: "Payment Method",
    paymentGateway: "Payment Gateway",
    billingInformation: "Billing Information",
    zipPostal: "Zip/Postal",
    billingAddress: "Billing Address",
    paymentInformation: "Payment Information",
    orderSummary: "Order Summary",
    totalAmount: "Total Amount",
    orderConfirmation: "Order Confirmation",
    paymentFailed: "Payment Failed",
    paymentSuccessful: "Payment Successful",
    thankForPurchase: "Thank you for your purchase",
    certificateNo: "Certificate No",
  },
  dashboard: {
    buyInsurance: "Buy Insurance",
    myInsurance: "My Insurance",
    claimRequest: "Claim Request",
    travel: "Travel Guide",
  },
  menu: {
    dashboard: "Dashboard",
    logOut: "Log out",
    changePassword: "Change password",
    hospital: "Hospital",
    serviceUnit: "Service Units",
    policy: "Policy",
    profile: "Profile",
    page: "Pages",
  },

  button: {
    login: "Login",
    submit: "Submit",
    clear: "Clear",
    confirm: "Confirm",
    ok: "OK",
    cancel: "Cancel",
    next: "Next",
    previous: "Previous",
    policySchedule: "Policy Schedule",
    register: "Register",
    logOut: "Log Out",
    addMember: "Add Member",
    buyNow: "Buy Now",
    payNow: "Pay Now",
    close: "Close",
    agree: "I agree",
    makePayment: "Make Payment",
  },
  dialog: {
    saveTitle: "Do you want to save the information ?",
    deleteTitle: "Do you want to delete this information?",
    saveDetail: "Please make sure that the information is correct.",
    changeOwnerTitle: "Car data already exist.",
    changeOwnerDetail:
      "This car is reserved for someone else. Are you sure to change car owner ?",
    SummaryReport: "Technical inspection The Summary report",
  },

  errorDialog: {
    title: "Connect Error",
    detail: "Error Connection",
  },
  rules: {
    requirePhone: "The phone field is required",
    errorLogin: "Username or password incorrect",
    enterEmail: "The email field is required",
    enterPassword: "Enter your password",
    require: "Require",
    space: "No space are allow",
    passwordMatch: "Password and Confirm password not match",
    password: "Password incorrect",
    passwordLength: "Password must be at least 6 characters",
    number: "Must be only number",
    character: "Must be only character",
    max45: "Max 45 characters",
    min3: "Min 3 characters",
    min16: "Min 16 numbers",
    max255: "Max 255 characters",
    max19: "Max 19 numbers",
    max4: "Max 4 numbers",
    emailForm: "The email must be valid",
    emailNotFound: "The email or password is incorrect",
    telephone: "The phone number or password is incorrect",
    identNo: "Must be 9 or 15 character",
    imageSize: "Image size should be less than 1 MB!",
  },
  title: {
    loginTitle: "Login",
    changePassword: "Change password",
    editProfile: "Edit Profile",
    claimHistory: "Claim History",
    claimDetail:"Claim Details"
  },
  policy: {
    policy: `<div>
    <h4 style="text-align: center; margin: 15px 0 20px 0">
      COVID-19 PT CARE INSURANCE POLICY GENERAL CONDITION
    </h4>
    <p style="font-weight: bold">1. INSURANCE AGREEMENT</p>
    <p>
      This insurance agreement is based upon the information provided by the
      applicant in the form requesting insurance coverage, and the status of
      Covid-19 questionnaire signed by the applicant for the purpose of
      obtaining insurance coverage. In the event that an applicant misrepresents
      or omits to inform the company of any relevant facts, the company when
      aware of the true situation, may decide to increase the premium level or
      void the policy.
    </p>
    <p style="font-weight: bold">2. DEFINITIONS</p>
    <p>
      In the insurance policy or cover agreement attached to this policy:<br />

      <b>“Covid 19”</b> <br />
      means the Coronavirus disease is an infectious disease caused by the
      SARS-CoV-2 virus<br />

      <b>“Company or Insurer”</b><br />
      means a Company issuing this Insurance Policy.<br />

      <b>“Subscriber”</b><br />
      means a person proposed as Insured in the Schedule.<br />

      <b>“Insured”</b> <br />means a person named as Insured in the Schedule.<br />

      <b>“Schedule”</b><br />
      means a Schedule of this Insurance Policy.<br />

      <b>“Illness”</b> <br />means physical illness sustained by the Insured due
      to infection or internal bodily abnormality due to Covid-19 infection
      which is medically necessary to be treated in a hospital.<br />

      <b>“Physician”</b> <br />
      means a physician duly license to practice medicine and is a member of the
      Medical Council under the Medical Profession Act, and not the Insured
      him/herself.
      <br />
      <b>“Day(s)”</b> <br />means the number of day(s) for which the hospital in
      which the Insured stayed charges the Insured for the hospital room as
      defined by that hospital.<br />

      <b>“Operation”</b><br />
      means medical necessary treatment by mean of standard surgery operation
      according to currently accepted medical practice as performed by a
      physician qualified for such treatment.<br />

      <b>“Hospital”</b> <br />means a public or private Covid-19 care
      establishment providing treatment to patients, which is authorized by the
      Ministry of Health in Lao PDR, which is installed with medical equipment,
      which is able to admit patients and which has a number of Covid- 19-care
      professionals in accordance with the Covid-19-care practice and
      regulations and provides 24-hours service, but not including
      rehabilitations or convalescent facilities or any similar institution or
      establishments provided specially for patients suffering from alcoholism,
      or addiction to narcotics or other addicted substances.<br />

      <b>“In-patient”</b><br />
      means a patient who has been admitted to a hospital for medically
      necessary treatment for a continual period of not less than 6 hours.<br />

      <b>“Out-patient”</b> <br />means a patient who receives a medically
      necessary examination and treatment in the out-patient ward or emergency
      ward of a hospital or clinic, as well as a person undergoing a minor
      operation who has demonstrated no medical indications that he/she needs to
      be admitted as an in-patient.<br />

      <b>“Hospitalization”</b> <br />means a stay for medically necessary
      treatment in a hospital for a continuous period of time, the service
      charges for are at the standard rates specified by the hospital.
      Hospitalization of more than one occasion due to the same cause or
      complications of the same disease, with the interval between each occasion
      of not more than 60 days, shall be treated as one and the same
      hospitalization.
      <br />
      <b>“Clinic”</b><br />
      means a public or private Covid-19 care establishment, which is authorized
      by the Ministry of Covid-19, in which basic medical equipment and Covid-19
      care professionals are available, but which is not able to admit patients.

      <br />
      <b>“Waiting period”</b> <br />
      means the time span during which the insured cannot claim for the benefits
      of the insurance policy from the insurer. The insured must wait for a
      specified amount of time as defined in the general condition before the
      insured could make a claim.
    </p>
    <p style="font-weight: bold">3. RULES FOR SUBSCRIPTIONS</p>
    <p>
      3.1 Fill in the application form for Covid-19<br />
      3.2 A Covid-19 questionnaire must be fully filled and attached.<br />
      3.3 Insured’s Age between 1 month 1 day to 90 years old.<br />
      3.4 Insured’s Age between 1 month 1 day to 15 years old, the policy must
      be joined with their parents and a person authority.<br />
      3.5 Insured must have fully vaccinated<br />

      According to the Ministry of Health, travelers are considered fully
      vaccinated if:<br />

      • They get their second dose of a 2-dose vaccine no less than 14 days
      before their travel date to Laos.<br />
      • They get a single-dose vaccine no less than 14 days before their travel
      date to Laos.<br />
      • In case of mix-and-match vaccines, travelers should get their second
      dose of a different vaccine within the recommended interval of the first
      vaccine no less than 14 days before their travel date to Laos.<br />

      List of approved COVID-19 vaccines in Laos<br />

      1. CoronaVac (Sinovac) – 2 doses needed / 2nd dose after 2-4 weeks;<br />
      2. AstraZeneca (Vaxzevria, Covishield) – 2 doses needed / 2nd dose after
      4-12 weeks;<br />
      3. Pfizer-BioNTech (Comirnaty, Tozinameran) – 2 doses needed / 2nd dose
      after 3 week;<br />
      4. Janssen (Johnson & Johnson) – 1 dose needed;<br />
      5. Moderna (Spikevax) – 2 doses needed / 2nd dose after 4 weeks);<br />
      6. COVILO (Sinopharm) – 2 doses needed / 2nd dose after 3-4 weeks);<br />
      7. Sputnik V – 2 doses needed / 2nd dose after 3 weeks);<br />
      8. Covaxin – 2 doses needed / 2nd dose after 4 weeks;<br />
      9. Novavax (Nuvaxovid) / Covovax – 2 doses needed / 2nd dose after 3
      weeks);<br />
      10. Medigen – 2 doses needed / 2nd dose after 4 weeks;<br />
      11. TURKOVAC / ERUCOV-VAC– 2 doses needed / 2nd dose after 4 weeks.<br />
    </p>
    <p style="font-weight: bold">4. PAYMENT OF INSURANCE PREMIUM</p>
    <p>
      The contract comes into force between the two parties at the time of
      signing and the premium has been fully paid. The policy shall expires
      completely at the fixed date recorded in the Schedule.
    </p>
    <p style="font-weight: bold">5. MODIFICATION OF CONTRACT</p>
    <p>
      The Insured or an agent of the Company has no authority to specify or
      modify any statement herein. Any modification or specification must be
      made by the authorized directors of the Company evidenced by their
      signatures and company seal.
    </p>
    <p style="font-weight: bold">6. RIGHT OF BENEFIT CLAIM</p>
    <p>
      When the Insured sustains an injury or illness and has to undergo medical
      treatment whether as an in-patient or out-patient, the following actions
      must be taken:<br />

      6.1 The Insured or his/her representative shall inform the Company in
      writing of the injury or illness within 30 days from the first date of
      hospitalization unless it can be proved that he/she has not been
      reasonably possible to give such notice during the prescribed period and
      that notice must be given to the Company as soon as reasonably
      possible.<br />

      6.2 The Insured or his/her representative, at the Insured’s expense, shall
      furnish to the Company definite evidence of hospitalization or medical
      report of the attending physician using the form prescribed by the Company
      within 30 days of the date of discharge from the hospital.<br />
      6.3 The Company, at its own expense, reserves the right to appoint a
      physician to perform a physical examination of the Insured, as it may see
      fit while the Insured is being hospitalized and claim for the benefit
      under this Policy is being made.
    </p>
    <p style="font-weight: bold">7. EXAMINATION RIGHTS</p>
    <p>
      The company has the right to medically examine a person covered who is
      claiming benefit under this policy and it has the right to conduct an
      autopsy, within the limits of the Law, in case of death.
    </p>
    <p style="font-weight: bold">8. REQUIRED DOCUMENTS:</p>
    <p>
      The insured must provide the following documents to file the claim to the
      company:<br />

      8.1. Original RT-PCR test result to prove for the infection.<br />
      8.2. Original invoice from the hospital or the clinic.<br />
      8.3. Original Medical Diagnosis.<br />
      8.4. Original Medical Prescription.<br />
      8.5. Copied of Passport. Note: in case the insured has paid for the
      treatment upfront or otherwise the partnered hospital or the clinic shall
      send this document to the company directly.
    </p>

    <p style="font-weight: bold">9. OTHER INSURANCE:</p>
    <p>
      If the Insured receives compensation from government or other welfare or
      from other insurer, the Company is responsible only for excess amount
      shown in the Schedule, over that which is covered under the welfare or
      other insurer’s policy.<br />

      Even though the insured may give up rights to other insurers, this will
      not affect the right and duty of the company to pay according to this
      insurance policy.
    </p>
    <p style="font-weight: bold">
      10. NOTICE OF TERMINATION OF INSURANCE POLICY
    </p>
    <p>
      10.1 The coverage shall cease immediately upon the occurrence of any of
      Non-payment of premium within the grace period.<br />
      10.2 The company can terminate this policy by giving notice in writing not
      less than 30 days in advance by registered mail to the insured at the last
      known address as declare to the company. In this event, the company shall
      refund the premium to the insured on a pro- rata basis.<br />
      10.3 The insured can terminate this policy by giving notice to the company
      in writing and shall be entitled to receive a refunded premium after
      deducting the premium for the period that the policy has been in force. In
      this event, the company will refund the premium on a pro-rata basis. The
      non-refundable parts of the premium are the government VAT, government
      issuing fee, and processing fee at the rate of 15%.<br />
      10.4 Notice of termination or cessation of this insurance policy for any
      reason will not result in the loss of right to claim reimbursement for
      covered costs occurring before date of termination or date of cessation of
      policy.<br />
      10.5 If the insured or person covered dies while the policy is still in
      force due to other reason excepted from the cover given under this
      insurance policy, the company will refund his/her premium on a pro-rata
      basis.
    </p>
    <p style="font-weight: bold">11. WAITING PERIOD:</p>
    <p>
      11.1 Immediately after ATK or PCR test confirm “Negative”<br />
      11.2 72 hours in case no ATK or PCR test performed.
    </p>
    <p style="font-weight: bold">12. EXCLUSIONS</p>
    <p>
      This insurance policy will not cover losses coming from or because of:<br />

      12.1 Infection of Covid 19 before the insurance subscription and before
      entering to Lao PDR or while in waiting period.<br />
      12.2 Medical conditions existing prior to date of policy commencement.<br />
      12.3 Suicide or attempted suicide or self - inflicted injury.<br />
      12.4 War (Whether declared or not) invasion or acts of foreign enemies,
      civil war, revolution, rebellion, riot strikes or civil commotion against
      the Government.<br />
      12.5 While the insured or person covered is a member of the armed
      services, police or volunteers and takes part in any war or
      suppression.<br />
      12.6 Nuclear weapons, nuclear fission, fusion or radioactive
      contaminate.<br />
      12.7 Medical services in connection with treatments for Acquired
      Immunization Deficiency syndrome (AIDS).
    </p>
    <p style="font-weight: bold">13. DETAILS OF BENEFITS:</p>
    <p>
      Benefits are payable for reasonable and necessary medical expense actually
      incurred during the cover period. The Company will pay the above benefits
      to the Insured as per the actual charges, but the total amount will not be
      greater than the “Hospital Service” benefit specified in the Schedule per
      one hospitalization.
    </p>
  </div>`,
  },
};
