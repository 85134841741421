import app from "../main";

const state = {
  language: "la",
};

const mutations = {
  SET_LANG(state, payload) {
    app.$i18n.locale = payload;
    state.language = payload;
  },
};

const actions = {
  setLang({ commit }, payload) {
    commit("SET_LANG", payload);
  },
};

const language = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default language;
