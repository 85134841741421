import Vue from "vue";
import VueI18n from "vue-i18n";
import enLanguage from "./en";
import laLanguage from "./la";

Vue.use(VueI18n);

const messages = {
  en: enLanguage,
  la: laLanguage,
};

const i18n = new VueI18n({
  locale: "en",
  messages,
  fallBackLocale: "en",
});

export default i18n;
