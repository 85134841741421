<template>
  <!-- Layout Footer -->
  <a-layout-footer>
    <a-divider style="height: 2px; background-color: #7cb305" />
    <a-row type="flex">
      <a-col :span="24" :md="12">
        <!-- Footer Copyright Notice -->
        <p class="copyright">
          Copyright © 2022 - {{ year }} All rights reserved
          <a href="https://laostp.com.la"></a>
        </p>
        <!-- / Footer Copyright Notice -->
      </a-col>
      <a-col :span="24" :md="12" class="footer-control">
        <!-- Footer Navigation Menu -->
        <a-menu mode="horizontal">
          <a-menu-item>
            <router-link to="/hospital">{{
              $t("menu.hospital")
            }}</router-link></a-menu-item
          >
          <a-menu-item>
            <router-link to="/service-units">
              {{ $t("menu.serviceUnit") }}
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/policy">
              {{ $t("menu.policy") }}
            </router-link>
          </a-menu-item>
        </a-menu>
        <!-- / Footer Navigation Menu -->
      </a-col>
    </a-row>
  </a-layout-footer>
  <!-- / Layout Footer -->
</template>

<script>
export default {
  data() {
    return { year: new Date().getFullYear() };
  },
  methods: {
    serviceUnit() {
      return this.$router.push("/service-units");
    },
  },
};
</script>
