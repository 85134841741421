export const billing = {
  namespaced: true,
  state: {
    billingData: {},
  },
  mutations: {
    setBilling(state, payload) {
      state.billingData = payload;
    },
  },
  actions: {
    updateBilling({ commit }, payload) {
      commit("setBilling", payload);
    },
  },
  getters: {
    getBilling: (state) => state.billingData,
  },
};
