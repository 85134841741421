import Vue from "vue";
import VueRouter from "vue-router";
import { lsread } from "../utils/storage";

Vue.use(VueRouter);

let routes = [
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Sign-In.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/guide",
    name: "Guide",
    component: () => import("../views/Guide.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/layout",
    name: "Layout",
    layout: "dashboard",
    component: () => import("../views/Layout.vue"),
  },
  {
    path: "/hospital",
    name: "Hospitals",
    layout: "dashboard",
    component: () => import("../views/Hospitals.vue"),
  },
  {
    path: "/service-units",
    name: "Service Units",
    layout: "dashboard",
    component: () => import("../views/ServiceUnits.vue"),
  },
  {
    path: "/policy",
    name: "Policy",
    layout: "dashboard",
    component: () => import("../views/Policy.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    layout: "dashboard",
    meta: {
      layoutClass: "layout-profile",
    },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/buy",
    name: "Buy Insurance",
    layout: "dashboard",
    component: () => import("../views/Buy-Insurance.vue"),
  },
  {
    path: "/my-insurance",
    name: "My Insurance",
    layout: "dashboard",
    component: () => import("../views/myInsurance/MyInsurance.vue"),
  },
  {
    path: "/payment/:id",
    name: "Payment",
    layout: "dashboard",
    component: () => import("../views/payment/Payment.vue"),
  },
  {
    path: "/payment/:id/credit",
    name: "Credit",
    layout: "dashboard",
    component: () => import("../views/payment/components/Credit.vue"),
  },
  {
    path: "/payment/:id/gateway",
    name: "Payment gateway",
    layout: "dashboard",
    component: () => import("../views/payment/components/PaymentGateway.vue"),
  },
  {
    path: "/payment/:id/billing",
    name: "Billing",
    layout: "dashboard",
    component: () => import("../views/payment/components/Billing.vue"),
  },
  {
    path: "/payment/:id/confirm",
    name: "Order Confirmation",
    layout: "dashboard",
    component: () => import("../views/payment/components/OrderConfirmation.vue"),
  },
  {
    path: "/payment/:id/success",
    name: "Payment Success",
    layout: "dashboard",
    component: () => import("../views/payment/components/PaymentSuccess.vue"),
  },
  {
    path: "/payment/:id/failed",
    name: "Payment Failed",
    layout: "dashboard",
    component: () => import("../views/payment/components/PaymentFailed.vue"),
  },
  {
    path: "/claim",
    name: "Claim",
    layout: "dashboard",
    component: () => import("../views/Claim/Claim.vue"),
  },
  {
    path: "/claim/detail/:id",
    name: "Claim / Detail",
    layout: "dashboard",
    component: () => import("../views/Claim/ClaimDetail.vue"),
  },
  {
    path: "/travel-guide",
    name: "Travel",
    layout: "dashboard",
    component: () => import("../views/Travel/Travel.vue"),
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/ForgetPassword/ForgetPassword"),
  },
  {
    path: "/sign-up",
    name: "Sign-Up",
    meta: {
      layoutClass: "layout-sign-up",
    },
    component: () => import("../views/Sign-Up.vue"),
  },
];

function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/home",
    "/",
    "/guide",
    "/contact",
    "/sign-up",
    "/forget-password",
  ];

  const authRequired = !publicPages.includes(to.path);
  const loggedIn = lsread("user");

  if (authRequired && !loggedIn) {
    return next("/");
  }

  next();
});

export default router;
