export default {
  flag: "la",
  language: "LA",
  error: {
    pageTitle: "ຄວາມຜິດພາດ",
    401: "session ຂອງທ່ານສິ້ນສຸດລົງ, ກະລຸນາເຂົ້າລະບົບແລະລອງໃໝ່ອີກຄັ້ງ.",
    404: "ຂໍອະໄພ, ໜ້າເວັບທີ່ທ່ານເຂົ້າເບິ່ງບໍ່ມີຢູ່.",
    500: "ຂໍ້ຜິດພາດຂອງເຊີບເວີພາຍໃນ, ກະລຸນາລໍ.",
    dataExist: "ຂໍ້ມູນມີຢູ່ແລ້ວ",
    getImage: "ກະລຸນາເລືອກຮູບ",
  },
  action: {
    AddButton: "ເພີ່ມໃໝ່",
    EditButton: "ແກ້ໄຂ",
    DeleteButton: "ລົບ",
    CancelButton: "ຍົກເລີກ",
    BackButton: "ກັບຄືນ",
    viewButton: "ເບີ່ງລາຍລະອຽດ",
    MoreDetailButton: "ລາຍລະອຽດ",
    RetryPaymentButton: "ລອງຊຳລະອີກຄັ້ງ",
    BackHomeButton: "ກັບສູ່ໜ້າຫຼັກ",
    Loading: "ກຳລັງໂຫຼດ...",
    PrintCertificate:"ປຣີ້ນໃບສັນຍາ"
  },
  forgetPass: {
    description: "ໃຊ້ແບບຟອມລຸ່ມນີ້ເພື່ອສ້າງລະຫັດຜ່ານໃໝ່.",
    resetPass: "ສ້າງລະຫັດຜ່ານໃໝ່",
    resetPassWith: "ສ້າງລະຫັດຜ່ານໃໝ່ດ້ວຍ",
  },
  form: {
    description: "ໃຊ້ແບບຟອມລຸ່ມນີ້ເພື່ອເຂົ້າສູ່ລະບົບ ຫຼື ສ້າງບັນຊີໃໝ່.",
    loginWith: "ເຂົ້າ​ສູ່​ລະ​ບົບດ້ວຍ",
    registerWith: "ລົງທະບຽນດ້ວຍ",
    or: "ຫຼື",
    phone: "ເບີໂທລະສັບ",
    email: "ອີມີວ",
    password: "ລະຫັດຜ່ານ",
    newPassword: "ລະຫັດຜ່ານໃໝ່",
    forgetPassword: "ລືມລະຫັດຜ່ານ",
    confirmPassword: "ຢືນຢັນລະຫັດຜ່ານ",
    dontHaveAccount: "ຍັງບໍ່ມີບັນຊີ",
    haveAccount: "ມີບັນຊີແລ້ວ",
    otp: "OTP",
    firstName: "ຊື່",
    lastName: "ນາມສະກຸນ",
    gender: {
      title: "ເພດ",
      male: "ຊາຍ",
      female: "ຍິງ",
      other: "ອື່ນຯ",
    },
    birthday: "ວັນເດືອນປີເກິດ",
    passportNumber: "ເລກໜັງສືເດີນທາງ",
    relationship: {
      title: "ສາຍສຳພັນ",
      couple: "ຜົວເມຍ",
      children: "ລູກ",
      parents: "ພໍ່ແມ່",
      friend: "ເພື່ອນ",
      other: "ອື່ນຯ",
    },
    document: "ເອກະສານປະກອບ",
    passport: "ໜັງສືເດີນທາງ",
    visa: "ວີຊ່າ",
    other: "ອື່ນໆ",
    iAgree: "ຂ້ອຍຍອມຮັບ",
    termCondition: "ຂໍ້ກໍານົດ ແລະ ເງື່ອນໄຂ",
    makeClaimRequest: "ຮ້ອງຂໍການເຄຣມ",
    claimToMember: "ເຄຣມໃຫ້",
    claimPurpose: "ຈຸດປະສົງການເຄຣມ",
    claimAmount: "ຈຳນວນຂໍເຄຣມ",
    claimDate: "ວັນເວລາ",
    hospitalAndService: "ໂຮງໝໍ ແລະ ຈຸດບໍລິການ",
    medicine: "ຄ່າຢາ",
    checkup: "ຄ່າກວດ",
    died: "ເສຍຊີວິດ",
    selectPerson: "ກະລຸນາເລືອກ",
    choosePurpose: "ກະລຸນາເລືອກຈູດປະສົງ",
    chooseHospital: "ກະລຸນາເລືອກໂຮງໝໍ",
    enterDescription: "ກະລຸນາໃຫ້ຄຳອະທີບາຍ",
    city: "ເມືອງ",
    address: "ທີ່ຢູ່",
    purposeVisit: "ຈຸດປະສົງທີ່ເຂົ້າມາລາວ",
    stateProvince: "ລັດ/ແຂວງ",
    country: "ປະເທດ",
    paymentType: "ປະເພດບັດ",
    cardNumber: "ເລກບັດ",
    expirationDate: "ວັນໝົດອາຍຸ",
    cvv: "CVV",
    tour:"ທ່ອງທ່ຽວ",
    work: "ເຮັດວຽກ",
    work30: "ເຮັດວຽກ (ຫຼາຍກວ່າ 30 ວັນ)"
  },
  label: {
    insuranceCategory: "ປະເພດປະກັນໄພ",
    insurancePackage: "ແພັກເກັດ",
    listMember: "ລາຍຊື່ຈຳນວນສະມາຊີກ",
    includeMe: "ລວມທັງຂ້ອຍພ້ອມ",
    payment: "ຈ່າຍເງິນ",
    search: "ຄົ້ນຫາ",
    price: "ມູນຄ່າ",
    single: "ດ່ຽວ",
    family: "ຄອບຄົວ",
    product: "ສີນຄ້າ",
    type: "ປະເພດ",
    status: "ສະຖານະ",
    createdDate: "ສ້າງເມື່ອວັນທີ",
    amount: "ຈຳນວນເງີນ",
    myInsuranceDetail: "ລາຍລະອຽດປະກັນຂອງຂ້ອຍ",
    claimRequest: "ຂໍເຄຣມ",
    requestAt: "ຂໍເຄຣມທີ",
    claimId: "ລະຫັດເຄຣມ",
    claimTo: "ເຄຣມໃຫ້",
    claimDescription: "ຄຳອະທີບາຍ",
    claimDate: "ມື້ຂໍເຄຣມ",
    noClaimRequest: "ບໍ່ມີການຂໍເຄຣມ",
    noClaimAvailable: "ບໍ່ມີໃບສັນຍາທີ່ສາມາດເຄຣມໄດ້",
    listHospital: "ລາຍຊື່ໂຮງໝໍ",
    listServiceUnit: "ລາຍຊື່ຈຸດບໍລິການ",
    image: "ຮູບພາບ",
    type: "ປະເພດ",
    name: "ຊື່",
    tel: "ເບີໂທ",
    address: "ທີ່ຢູ່",
    map: "ແຜນທີ",
    location: "ທີ່ຕັ້ງ",
    ancientMystery: "ຄວາມລຶກລັບທາງບູຮານສະຖານ",
    buddhismAndBelief: "ພຸດທະສາສະໜາ ແລະ ຄວາມເຊື່ອ",
    theSecretWar: "ສະຖານທີສຳຄັນຍາມສົງຄາມ",
    wondersOfNature: "ສິ່ງມະຫັດສະຈັນທາງທໍາມະຊາດ",
    authenticCulture: "ວັດທະນະທໍາທີ່ຫຼາກຫຼາຍ",
    legendaryLandmarks: "ສະຖາປັດຕະຍະກຳທີ່ເປັນຕຳນານ",
    profileInfo: "ຂໍ້ມູນໂປຣຟາຍ",
    paymentMethod: "ວິທີການຊຳລະເງີນ",
    paymentGateway: "ຊ່ອງທາງການຊຳລະເງີນ",
    billingInformation: "ຂໍ້ມູນບີນຊຳລະເງີນ",
    zipPostal: "ລະຫັດໄປສະນີ",
    billingAddress: "ທີ່ຢູ່ໃບບີນ",
    paymentInformation: "ຂໍ້ມູນການຊຳລະເງີນ",
    orderSummary: "ສະຫຼຸບການຊຳລະ",
    totalAmount: "ຈໍາ​ນວນເງີນ​ທັງ​ຫມົດ",
    orderConfirmation: "ຢືນຢັນການສັ່ງຊື້",
    paymentFailed: "ການຊຳລະເງິນລົ້ມເຫລວ",
    paymentSuccessful: "ການ​ຊໍາ​ລະ​ສໍາ​ເລັດ​",
    thankForPurchase: "ຂອບໃຈສໍາລັບການໃຊ້ບໍລິການ",
    certificateNo: "ລະຫັດໃບສັນຍາ",
  },

  dashboard: {
    buyInsurance: "ຊື້ປະກັນ",
    myInsurance: "ປະກັນຂອງຂ້ອຍ",
    claimRequest: "ຂໍເຄຣມ",
    travel: "ຂໍ້ມູນທ່ອງທ່ຽວ",
  },
  menu: {
    dashboard: "ໜ້າຫຼັກ",
    logOut: "ອອກ​ຈາກ​ລະ​ບົບ",
    changePassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    hospital: "ຂໍ້ມູນໂຮງໝໍ",
    serviceUnit: "ຂໍ້ມູນຈຸດບໍລິການ",
    policy: "ນະໂຍບາຍ",
    profile: "ໂປຣຟາຍ",
    page: "ໜ້າ",
  },
  button: {
    login: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    register: "ລົງທະບຽນ",
    submit: "ບັນທຶກ",
    ok: "ຕົກລົງ",
    clear: "ລຶບຂໍ້ມູນ",
    confirm: "ຢືນຢັນ",
    cancel: "ຍົກເລີກ",
    next: "ຖັດໄປ",
    previous: "ກ່ອນໜ້ານີ້",
    policySchedule: "ນະໂຍບາຍ",
    logOut: "ອອກຈາກລະບົບ",
    addMember: "ເພີ່ມສະມາຊີກ",
    buyNow: "ຊື້ເລີຍ",
    payNow: "ຊຳລະ",
    close: "ປິດ",
    agree: "ຂ້ອຍຍ້ອມຮັບ",
    makePayment: "ຊຳລະເງີນ",
  },
  dialog: {
    saveTitle: "ທ່ານຕ້ອງການບັນທຶກຂໍ້ມູນບໍ່?",
    deleteTitle: "ທ່ານຕ້ອງການລົບຂໍ້ມູນບໍ່?",
    saveDetail: "ກະລຸນາກວດສອບໃຫ້ແນ່ໃຈວ່າຂໍ້ມູນຖືກຕ້ອງ.",
  },
  dataDialog: {
    title: "ບັນທຶກຂໍ້ມູນສຳເລັດ",
    userId: "Owner_Id: ",
    name: "ຊື່: ",
    username: "Username: ",
    password: "Password: ",
  },
  errorDialog: {
    title: "ເກີດຂໍຜີດພາດ",
    detail: "ການເຊື່ອມຕໍ່ມີຂໍຜີດພາດໃນຕອນນີ້",
  },
  rules: {
    requirePhone: "ກະລຸນາໃສ່ເບີໂທ",
    errorLogin: "ລະຫັດຜູ້ໃຊ້ຫລືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
    enterEmail: "ກະລຸນາໃສ່ອີມີວ",
    enterPassword: "ກະລຸນາໃສ່ລະຫັດຜ່ານ",
    require: "ບໍ່ສາມາດວ່າງໄດ້",
    space: "ຢ່າມີຊ່ອງຫວ່າງ",
    passwordMatch: "ລະຫັດຜ່ານ ແລະ ລະຫັດຢືນຢັນບໍ່ກົງກັນ",
    password: "ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
    passwordLength: "ລະຫັດຜ່ານຕ້ອງມີຢ່າງໜ້ອຍ 6 ຕົວອັກສອນ",
    number: "ຕ້ອງເປັນຕົວເລກເທົ່ານັ້ນ",
    character: "ຕ້ອງເປັນຕົວອັກສອນເທົ່ານັ້ນ",
    max45: "ສູງສຸດ 45 ຕົວອັກສອນ",
    min3: "ຢ່າງໜ້ອຍ 3 ຕົວອັກສອນ",
    min16: "ຢ່າງໜ້ອຍ 16 ຕົວເລກ",
    max255: "ສູງສຸດ 255 ຕົວອັກສອນ",
    max19: "ສູງສຸດບໍ່ເກີນ 19 ຕົວເລກ",
    max4: "ສູງສຸດ 4 ຕົວເລກ",
    emailForm: "ອີເມວບໍ່ຖືກຕ້ອງ",
    emailNotFound: "ອີມີວ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
    telephone: "ເບີ​ໂທລະ​ສັບ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ",
    imageSize: "ຂະໜາດຮູບພາບຄວນນ້ອຍກວ່າ 1 MB!",
  },
  title: {
    loginTitle: "ເຂົ້າ​ສູ່​ລະ​ບົບ",
    changePassword: "ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ",
    editProfile: "ແກ້ໄຂໂປຣໄຟລ",
    claimHistory: "ປະຫວັດການເຄຣມ",
    claimDetail:"ລາຍລະອຽດການເຄຣມ"
  },

  policy: {
    policy: `
    <div>
        <div class="row mb-5">
            <div class="col-lg-12 mx-auto text-center">
                <h4 class="mt-4 section-heading">
                    ເງື່ອນໄຂທົ່ວໄປຂອງນະໂຍບາຍປະກັນໄພ COVID-19 PT CARE
                </h4>

            </div>
        </div>

        <p style="font-weight: bold; margin-top: 10px;"> 1. ສັນຍາປະກັນໄພ </p>
        ຂໍ້ຕົກລົງການປະກັນໄພນີ້ ແມ່ນອີງໃສ່ຂໍ້ມູນທີ່ສະໜອງໃຫ້ໂດຍຜູ້ສະໝັກໄວ້ໃນແບບຟອມການຮ້ອງຂໍການຄຸ້ມຄອງປະກັນໄພ ແລະ
        ສະຖານະຂອງແບບສອບຖາມກ່ຽວກັບ Covid-19 ທີ່ລົງນາມໂດຍຜູ້ສະໝັກເພື່ອຈຸດປະສົງທີ່ຈະໄດ້ຮັບການຄຸ້ມຄອງປະກັນໄພ.
        ໃນກໍລະນີທີ່ຜູ້ສະໝັກບິດເບືອນ ຫຼື ລະເວັ້ນການແຈ້ງໃຫ້ບໍລິສັດຮູ້ຂໍ້ເທັດຈິງທີ່ກ່ຽວຂ້ອງ,
        ເມື່ອບໍລິສັດຮູ້ສະຖານະການທີ່ແທ້ຈິງ, ອາດຈະມີການຕັດສິນໃຈເພີ່ມລະດັບທີ່ເບ້ຍປະກັນໄພ ຫຼື ຍົກເລີກນະໂຍບາຍ.<br>

        <p style="font-weight: bold; margin-top: 10px;">2. ການອະທິບາຍຄຳສັບ</p> ການອະທິບາຍຄຳສັບ
        ໃນນະໂຍບາຍປະກັນໄພ ຫຼື ຂໍ້ຕົກລົງການຄຸ້ມຄອງຂອງທີ່ຕິດຂັດມາກັບນະໂຍບາຍນີ້:<br>

        <b>“ໂຄວິດ 19”</b> ໝາຍເຖິງ ພະຍາດໂຄໂຣນາໄວຣັດ ແມ່ນພະຍາດຕິດຕໍ່ທີ່ເກີດມາຈາກໄວຣັດ
        SARS-CoV-2;<br>

        <b>“ບໍລິສັດ ຫຼື ຜູ້ຮັບປະກັນ”</b> ໝາຍເຖິງ ບໍລິສັດທີ່ອອກນະໂຍບາຍປະກັນໄພນີ້.<br>

        <b>“ສະມາຊິກ”</b> ໝາຍເຖິງ ບຸກຄົນທີ່ສະເໜີເປັນຜູ້ປະກັນໄພໃນກຳນົດການ.<br>

        <b>“ຜູ້ປະກັນໄພ”</b> ຫມາຍເຖິງ ບຸກຄົນທີ່ມີຊື່ເປັນຜູ້ປະກັນໄພໃນກຳນົດການ.<br>

        <b>“ກຳນົດການ”</b> ໝາຍເຖິງ ກຳນົດເວລາການຂອງນະໂຍບາຍປະກັນໄພນີ້.<br>

        <b>“ການເຈັບປ່ວຍ”</b> ໝາຍເຖິງ ການເຈັບປ່ວຍທາງກາຍທີ່ຜູ້ປະກັນໄພຕ້ອງທົນທຸກຍ້ອນການຕິດເຊື້ອ
        ຫຼືຄວາມຜິດປົກກະຕິທາງຮ່າງກາຍເນື່ອງຈາກການຕິດເຊື້ອ ໂຄວິດ-19 ເຊິ່ງຈຳເປັນຕ້ອງ
        ໄດ້ຮັບການປິ່ນປົວທາງການແພດຢູ່ໃນໂຮງໝໍ.<br>

        <b>“ແພດ”</b> ຫມາຍເຖິງ ແພດທີ່ມີໃບອະນຸຍາດທີ່ຖືກຕ້ອງເພື່ອປະກອບວິຊາຊີບ ແລະ
        ເປັນສະມາຊິກຂອງສະພາການແພດພາຍໃຕ້ກົດໝາຍວ່າດ້ວຍວິຊາຊີບທາງການແພດ
        ແລະ ບໍ່ແມ່ນຜູ້ປະກັນໄພເອງ.<br>

        <b>“ມື້”</b> ໝາຍເຖິງ ຈຳນວນມື້ທີ່ໂຮງໝໍທີ່ຮັບຜູ້ປະກັນພັກຮັກສາຕົວຢູ່ໄດ້ເສຍຄ່າຫ້ອງຂອງຜູ້ຮັບປະກັນຕາມໂຮງໝໍຕາມທີ່ກຳນົດໄວ້.<br>

        <b>“ການດໍາເນີນການ”</b> ຫມາຍເຖິງ
        ການປິ່ນປົວທີ່ຈໍາເປັນທາງດ້ານການແພດໂດຍວິທີການຜ່າຕັດຕາມມາດຕະຖານທາງການແພດທີ່ຍອມຮັບໃນປັດຈຸບັນທີ່ປະຕິບັດໂດຍແພດໝໍທີ່ມີຄຸນສົມບັດເໝາະສົມສໍາລັບການປິ່ນປົວດັ່ງກ່າວ.<br>

        <b>“ໂຮງໝໍ”</b> ໝາຍເຖິງ ສະຖາບັນປິ່ນປົວພະຍາດ ໂຄວິດ-19 ທັງພາກລັດ ຫຼື ເອກະຊົນ ທີ່ໃຫ້ບໍລິການປິ່ນປົວຄົນເຈັບ,
        ເຊິ່ງໄດ້ຮັບອະນຸຍາດຈາກກະຊວງສາທາລະນະສຸກ ສປປ ລາວ, ເຊິ່ງມີການຕິດຕັ້ງອຸປະກອນການແພດ, ເຊິ່ງສາມາດຮັບຄົນເຈັບໄດ້ ແລະ
        ມີຈຳນວນຜູ້ຕິດເຊື້ອ ໂຄວິດ-19. ຜູ້ຊ່ຽວຊານດ້ານການດູແລທີ່ສອດຄ່ອງກັບການປະຕິບັດ ແລະ ກົດລະບຽບການເບິ່ງແຍງຜູ້ປ່ວຍ
        ໂຄວິດ-19 ແລະ ໃຫ້ບໍລິການຕະຫຼອດ 24 ຊົ່ວໂມງ, ແຕ່ບໍ່ລວມເຖິງການຟື້ນຟູ ຫຼື ສະຖານທີ່ຟື້ນຟູ ຫຼື ສະຖາບັນ ຫຼື
        ສະຖາບັນທີ່ຄ້າຍຄືກັນທີ່ຈັດໄວ້ໃຫ້ສະເພາະສໍາລັບຄົນເຈັບທີ່ຕິດເຫຼົ້າ ຫຼື ການຕິດຢາເສບຕິດ ຫຼື ສິ່ງເສບຕິດອື່ນໆ.<br>

        <b>“ຄົນເຈັບໃນ”</b> ໝາຍເຖິງ ຄົນເຈັບທີ່ໄດ້ເຂົ້າໂຮງໝໍເພື່ອປິ່ນປົວຕາມຄວາມຈຳເປັນທາງແພດເປັນເວລາຢ່າງຕໍ່ເນື່ອງບໍ່ຕ່ຳກວ່າ 6
        ຊົ່ວໂມງ.<br>

        <b>“ຄົນເຈັບນອກ”</b> ໝາຍເຖິງ ຄົນເຈັບທີ່ໄດ້ຮັບການກວດ ແລະ ປິ່ນປົວທີ່ຈຳເປັນທາງດ້ານການແພດຢູ່ໃນຫ້ອງຄົນເຈັບນອກ ຫຼື
        ຫ້ອງສຸກເສີນຂອງໂຮງໝໍ ຫຼື ຄລີນິກ,
        ພ້ອມທັງຄົນເຈັບທີ່ຜ່າຕັດເລັກນ້ອຍທີ່ບໍ່ໄດ້ສະແດງການບົ່ງບອກທາງການແພດວ່າຜູ້ກ່ຽວຈະຕ້ອງເຂົ້າຮັບການປິ່ນປົວເປັນຄົນເຈັບໃນ.<br>

        <b>“ການຮັກສາໃນໂຮງໝໍ”</b> ໝາຍເຖິງ ການພັກເຊົາທີ່ຈຳເປັນທາງການແພດໃນໂຮງໝໍເປັນໄລຍະຕໍ່ເນື່ອງໂດຍຄິດຄ່າບໍລິການແມ່ນຢູ່ໃນອັດຕາມາດຕະຖານທີ່ໂຮງໝໍກຳນົດໄວ້.
        ການນອນໂຮງໝໍຫຼາຍກວ່າໜຶ່ງຄັ້ງຍ້ອນສາເຫດອັນດຽວກັນ ຫຼື ອາການແຊກຊ້ອນຂອງພະຍາດດຽວກັນ, ໂດຍແຕ່ລະຄັ້ງຫ່າງກັນບໍ່ເກີນ 60
        ວັນ ໃຫ້ຖືເປັນການຮັກສາໃນໂຮງໝໍຄັ້ງດຽວກັນ.<br>

        <b>“ຄລີນິກ”</b> ໝາຍເຖິງ ສູນປິ່ນປົວພະຍາດ ໂຄວິດ-19ທັງພາກລັດ ຫຼື ເອກະຊົນທີ່ໄດ້ຮັບອະນຸຍາດຈາກກະຊວງຂອງ ໂຄວິດ-19,
        ເຊິ່ງມີອຸປະກອນການແພດຂັ້ນພື້ນຖານ ແລະ ຜູ້ຊ່ຽວຊານດ້ານການປິ່ນປົວພະຍາດ ໂຄວິດ-19 ແຕ່ບໍ່ສາມາດຮັບຄົນເຈັບໄດ້.<br>

        <b>“ໄລຍະເວລາລໍຖ້າ”</b> ໝາຍເຖິງ ໄລຍະເວລາທີ່ຜູ້ປະກັນໄພບໍ່ສາມາດຮຽກຮ້ອງຜົນປະໂຫຍດຂອງນະໂຍບາຍປະກັນໄພຈາກຜູ້ປະກັນໄພໄດ້.
        ຜູ້ປະກັນໄພຕ້ອງລໍຖ້າໄລຍະເວລາທີ່ກໍານົດໄວ້ຕາມທີ່ໄດ້ກໍານົດໄວ້ໃນເງື່ນໄຂທົ່ວໄປກ່ອນທີ່ຜູ້ປະກັນໄພຈະສາມາດຮຽກຮ້ອງໄດ້.<br>

        <p style="font-weight: bold; margin-top: 10px">3. ກົດລະບຽບສຳລັບການສະໝັກສະມາຊິກ<br></p>
        3.1 ຕື່ມຂໍ້ມູນໃສ່ໃນແບບຟອມສະໝັກສຳລັບ ໂຄວິດ-19<br>
        3.2 ຕ້ອງຕອບແບບສອບຖາມ ໂຄວິດ-19 ໃຫ້ຄົບຖ້ວນ ແລະ ຄັດຕິດມານຳ.<br>
        3.3 ອາຍຸຂອງຜູ້ປະກັນໄພລະຫວ່າງ 1 ເດືອນ 1 ມື້ ເຖິງ 90 ປີ.<br>
        3.4 ອາຍຸຂອງຜູ້ປະກັນໄພລະຫວ່າງ 1 ເດືອນ 1 ມື້ ເຖິງ 15 ປີ, ນະໂຍບາຍຕ້ອງໄດ້ຮັບການເຂົ້າຮ່ວມກັບພໍ່ແມ່ ແລະ/ຫຼື
        ຜູ້ມີສິດອໍານາດລົງນາມ.<br>
        3.5 ຜູ້ປະກັນໄພຕ້ອງໄດ້ຮັບການສັກຢາປ້ອງກັນຢ່າງຄົບຖ້ວນ
        ອີງຕາມກະຊວງສາທາລະນະສຸກນັກທ່ອງທ່ຽວແມ່ນຖືວ່າໄດ້ຮັບການສັກຢາປ້ອງກັນຢ່າງເຕັມທີ່ຖ້າຫາກ:<br>
        • ພວກເຂົາໄດ້ຮັບວັກຊີນເຂັມທີ 2 ບໍ່ຕ່ຳກວ່າ 14 ມື້ກ່ອນມື້ເດີນທາງມາປະເທດລາວ.<br>
        • ພວກເຂົາໄດ້ຮັບວັກຊີນຄັ້ງດຽວ ບໍ່ຕ່ຳກວ່າ 14 ມື້ກ່ອນມື້ເດີນທາງມາປະເທດລາວ.<br>
        • ໃນກໍລະນີທີ່ສັກວັກຊີນປະສົມກັນ
        ນັກທ່ອງທ່ຽວຄວນໄດ້ຮັບວັກຊີນຊະນິດທີສອງຂອງພວກເຂົາພາຍໃນໄລຍະເວລາທີ່ແນະນໍາຂອງວັກຊີນແຂັມທໍາອິດບໍ່ຕໍ່າກວ່າ 14
        ມື້ກ່ອນມື້ເດີນທາງມາປະເທດລາວ.
        ລາຍຊື່ວັກຊີນ ໂຄວິດ-19 ທີ່ໄດ້ຮັບການອະນຸມັດໃນລາວ<br>
        1. CoronaVac (Sinovac) – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 2-4 ອາທິດ;<br>
        2. AstraZeneca (Vaxzevria, Covishield) – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 4-12 ອາທິດ;<br>
        3. Pfizer-BioNTech (Comirnaty, Tozinameran) – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 3 ອາທິດ;<br>
        4. Janssen (Johnson & Johnson) – ຕ້ອງສັກ 1 ໂດສ ;<br>
        5. Moderna (Spikevax) – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 4 ອາທິດ);<br>
        6. COVILO (Sinopharm) – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 3-4 ອາທິດ);<br>
        7. Sputnik V – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 3 ອາທິດ);<br>
        8. Covaxin – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 4 ອາທິດ;<br>
        9. Novavax (Nuvaxovid) / Covovax – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 3 ອາທິດ);<br>
        10. Medigen – ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 4 ອາທິດ;<br>
        11. TURKOVAC / ERUCOV-VAC– ຕ້ອງສັກ 2 ໂດສ / ໂດສທີ 2 ຫຼັງຈາກ 4 ອາທິດ;.<br>
        <p style="font-weight: bold; margin-top: 10px"> 4. ການຊຳລະເບ້ຍປະກັນໄພ</p>
        ສັນຍາດັ່ງກ່າວມີຜົນບັງຄັບໃຊ້ລະຫວ່າງສອງຝ່າຍໃນເວລາລົງນາມ ແລະ ຄ່າປະກັນໄພໄດ້ຖືກຈ່າຍຢ່າງເຕັມສ່ວນ.
        ນະໂຍບາຍຈະໝົດອາຍຸໂດຍສົມບູນໃນວັນທີກຳນົດທີ່ບັນທຶກໄວ້ໃນກຳນົດການ.<br>
        <p style="font-weight: bold; margin-top: 10px">5. ການດັດແກ້ສັນຍາ</p>
        ຜູ້ປະກັນໄພ ຫຼື ຕົວແທນຂອງບໍລິສັດບໍ່ມີອຳນາດທີ່ຈະລະບຸ ຫຼື ແກ້ໄຂຂໍ້ຄວາມໃດໆໃນນີ້. ການດັດແກ້ ຫຼື
        ການກໍານົດໃດໆຕ້ອງເຮັດໂດຍຜູ້ອໍານວຍການບໍລິສັດທີ່ໄດ້ຮັບອະນຸຍາດໂດຍມີຫຼັກຖານເປັນລາຍເຊັນ ແລະ ປະທັບຕາຂອງບໍລິສັດ.<br>

        <p style="font-weight: bold; margin-top: 10px">6. ສິດໃນການຮຽກຮ້ອງຜົນປະໂຫຍດ</p>
        ເມື່ອຜູ້ປະກັນໄດ້ຮັບບາດເຈັບ ຫຼື ເຈັບປ່ວຍ ແລະ ຕ້ອງໄດ້ຮັບການປິ່ນປົວທາງການແພດບໍ່ວ່າຈະເປັນຄົນເຈັບໃນ ຫຼື
        ຄົນເຈັບນອກ, ຈະຕ້ອງປະຕິບັດດັ່ງຕໍ່ໄປນີ້:<br>

        6.1 ຜູ້ປະກັນໄພ ຫຼື ຜູ້ຕາງໜ້າຂອງລາວຕ້ອງແຈ້ງໃຫ້ບໍລິສັດເປັນລາຍລັກອັກສອນກ່ຽວກັບການບາດເຈັບ ຫຼື ການເຈັບປ່ວຍພາຍໃນ
        30
        ມື້ນັບຈາກມື້ທໍາອິດທີ່ເຂົ້າໂຮງໝໍເວັ້ນເສຍແຕ່ວ່າມັນສາມາດພິສູດໄດ້ວ່າລາວບໍ່ສາມາດທີ່ຈະແຈ້ງເຫດດັ່ງກ່າວໃນໄລຍະເວລາທີ່ກໍານົດ
        ແລະ ຈະຕ້ອງແຈ້ງໃຫ້ບໍລິສັດຮັບຮູ້ໄວເທົ່າທີ່ຈະເປັນໄປໄດ້.<br>
        6.2 ຜູ້ປະກັນໄພ ຫຼື ຜູ້ຕາງໜ້າຂອງລາວ ຈະຕ້ອງມອບຫຼັກຖານຂອງການເຂົ້າໂຮງໝໍ ຫຼື
        ລາຍງານທາງການແພດຂອງແພດໝໍທີ່ມີສ່ວນຮ່ວມໃຫ້ບໍລິສັດຮັບຮູ້ຕາມແບບຟອມທີ່ບໍລິສັດໄດ້ກໍານົດໄວ້ພາຍໃນ 30
        ວັນນັບຈາກມື້ອອກຈາກໂຮງໝໍ. ໂດຍຜູ້ປະກັນໄພເປັນຜູ້ອອກຄ່າໃຊ້ຈ່າຍເອງ<br>
        6.3
        ບໍລິສັດຂໍສະຫງວນສິດໃນການແຕ່ງຕັ້ງແພດເພື່ອທຳການກວດຮ່າງກາຍຂອງຜູ້ປະກັນໄພຕາມທີ່ເຫັນເຫມາະສົມໃນຂະນະທີ່ຜູ້ປະກັນໄພກໍາລັງນອນຢູ່ໂຮງໝໍ
        ແລະ ຂໍຜົນປະໂຫຍດພາຍໃຕ້ນະໂຍບາຍນີ້ໂດຍການໃຊ້ຈ່າຍຂອງບໍລິສັດເອງ.<br>
        <p style="font-weight: bold; margin-top: 10px">7. ສິດໃນການກວດສອບ</p>
        ບໍລິສັດມີສິດໃນການກວດສອບທາງການແພດຜູ້ຂອງບຸກຄົນທີ່ໄດ້ຮັບການຄຸ້ມຄອງທີ່ອ້າງເອົາຜົນປະໂຫຍດພາຍໃຕ້ນະໂຍບາຍນີ້ ແລະ
        ມີສິດທີ່ຈະດໍາເນີນການພິສູດຊາກສົບ, ພາຍໃນຂອບເຂດຈໍາກັດຂອງກົດຫມາຍໃນກໍລະນີຂອງການເສຍຊີວິດ.
        <p style="font-weight: bold; margin-top: 10px">8. ເອກະສານທີ່ຕ້ອງການ:</p>
        ຜູ້ປະກັນໄພຕ້ອງໄດ້ກຽມເອກະສານດັ່ງຕໍ່ໄປນີ້ເພື່ອຍື່ນຄໍາຮ້ອງຕໍ່ກັບບໍລິສັດ:<br>
        8.1. ຜົນກວດ RT-PCR ສະບັບແທ້ເພື່ອພິສູດການຕິດເຊື້ອ.<br>
        8.2. ໃບຮຽກເກັບເງິນສະບັບແທ້ຈາກໂຮງໝໍ ຫຼື ຄລີນິກ.<br>
        8.3. ໃບວິນິດໄສທາງການແພດສະບັບແທ້.<br>
        8.4. ໃບສັ່ງຢາຈາກແພດສະບັບແທ້.<br>
        8.5. ສຳເນົາໜັງສືຜ່ານແດນ.<br>

        ໝາຍເຫດ: ໃນກໍລະນີທີ່ຜູ້ປະກັນຕົນໄດ້ຈ່າຍຄ່າປິ່ນປົວລ່ວງໜ້າແລ້ວ ຫຼື ທາງໂຮງໝໍທີ່ເປັນຄູ່ຮ່ວມງານ ຫຼື
        ຄລີນິກຕ້ອງສົ່ງເອກະສານນີ້ໃຫ້ບໍລິສັດໂດຍກົງ.<br>
        <p style="font-weight: bold; margin-top: 10px">9. ປະກັນໄພອື່ນໆ:</p>
        ຖ້າຜູ້ປະກັນໄພໄດ້ຮັບຄ່າຊົດເຊີຍຈາກລັດຖະບານ ຫຼື ສະຫວັດດີການອື່ນໆ ຫຼື
        ຈາກຜູ້ປະກັນໄພອື່ນໆບໍລິສັດຈະຮັບຜິດຊອບພຽງແຕ່ສໍາລັບຈໍານວນເງິນເກີນທີ່ສະແດງຢູ່ກຳນົດການ
        ເຊິ່ງຫຼາຍກວ່າທີ່ໄດ້ຮັບການຄຸ້ມຄອງພາຍໃຕ້ສະຫວັດດີການ ຫຼື ນະໂຍບາຍຂອງຜູ້ປະກັນໄພອື່ນໆ.<br>

        ເຖິງແມ່ນວ່າຜູ້ປະກັນໄພອາດຈະໃຫ້ສິດກັບຜູ້ປະກັນໄພອື່ນກໍຕາມຈະບໍ່ມີຜົນກະທົບຕໍ່ສິດ ແລະ
        ໜ້າທີ່ຂອງບໍລິສັດໃນການຈ່າຍເງິນຕາມນະໂຍບາຍປະກັນໄພນີ້.<br>
        <p style="font-weight: bold; margin-top: 10px"> 10. ການແຈ້ງການຢຸດຕິນະໂຍບາຍປະກັນໄພ </p>
        10.1 ການຄຸ້ມຄອງຈະສິ້ນສຸດໃນທັນທີທີ່ເກີດການບໍ່ຈ່າຍຄ່າບໍລິການໃດໆພາຍໃນໄລຍະການຜ່ອນຜັນ.<br>
        10.2 ບໍລິສັດສາມາດຍົກເລີກນະໂຍບາຍນີ້ໂດຍການໃຫ້ແຈ້ງການເປັນລາຍລັກອັກສອນລ່ວງໜ້າບໍ່ໜ້ອຍກວ່າ 30 ມື້
        ທາງໄປສະນີລົງທະບຽນຫາຜູ້ປະກັນໄພທີ່ທີ່ຢູ່ສຸດທ້າຍທີ່ໄດ້ແຈ້ງໄວ້ກັບບໍລິສັດ.
        ໃນກໍລະນີນີ້ນີ້ບໍລິສັດຈະຄືນເງິນຄ່າປະກັນໄພໃຫ້ກັບຜູ້ປະກັນໄພຕາມອັດຕາສ່ວນແບ່ງ.<br>
        10.3 ຜູ້ປະກັນສາມາດຍົກເລີກນະໂຍບາຍນີ້ໂດຍການແຈ້ງໃຫ້ບໍລິສັດເປັນລາຍລັກອັກສອນ ແລະ
        ມີສິດທີ່ຈະໄດ້ຮັບຄ່າເບ້ຍປະກັນໄພຄືນຫຼັງຈາກຫັກຄ່າປະກັນໄພສໍາລັບໄລຍະເວລາທີ່ນະໂຍບາຍດັ່ງກ່າວມີຜົນບັງຄັບໃຊ້.
        ໃນກໍລະນີນີ້ບໍລິສັດຈະຄືນເບ້ຍປະກັນໄພໃຫ້ຕາມສັດສ່ວນ.
        ພາກສ່ວນທີ່ບໍ່ສາມາດຄືນໄດ້ຂອງຄ່າປະກັນໄພແມ່ນອາກອນມູນຄ່າເພີ່ມຂອງລັດຖະບານ, ຄ່າທໍານຽມການອອກຂອງລັດຖະບານ ແລະ
        ຄ່າທໍານຽມການດໍາເນີນການໃນອັດຕາ 15%.<br>
        10.4 ແຈ້ງການຢຸດຕິ ຫຼື ການຍົກເລີກນະໂຍບາຍປະກັນໄພສະບັບນີ້
        ຈະບໍ່ສົ່ງຜົນໃຫ້ການສູນເສຍສິດໃນການຮ້ອງຂໍເງິນຄືນສໍາລັບຄ່າໃຊ້ຈ່າຍທີ່ຄວບຄຸມທີ່ເກີດຂື້ນກ່ອນວັນສິ້ນສຸດ ຫຼື
        ວັນທີຢຸດເຊົານະໂຍບາຍ.
        10.5 ຖ້າຜູ້ປະກັນໄພ ຫຼື ຜູ້ຄຸ້ມຄອງເສຍຊີວິດ ໃນຂະນະທີ່ນະໂຍບາຍຍັງຢູ່ໃນຜົນບັງຄັບໃຊ້ເນື່ອງຈາກເຫດຜົນອື່ນ
        ຍົກເວັ້ນການຄູ້ມຄອງກຳນົດພາຍໃຕ້ນະໂຍບາຍປະກັນໄພນີ້, ບໍລິສັດຈະຄືນເງິນຄ່າປະກັນໄພ ຄືນຕາມອັດຕາສ່ວນ.<br>
        <p style="font-weight: bold; margin-top: 10px"> 11. ໄລຍະເວລາລໍຖ້າ:</p>
        11.1 ທັນທີຫຼັງຈາກການກວດ ATK ຫຼື PCR ຢືນຢັນເປັນ "ລົບ"<br>
        11.2 72 ຊົ່ວໂມງໃນກໍລະນີທີ່ບໍ່ມີການກວດ ATK ຫຼື PCR .<br>

        <p style="font-weight: bold; margin-top: 10px"> 12. ການຍົກເວັ້ນ </p>
        ນະໂຍບາຍປະກັນໄພນີ້ຈະບໍ່ຄຸ້ມຄອງການສູນເສຍທີ່ມາຈາກ ຫຼື ຍ້ອນ:<br>

        12.1 ການຕິດເຊື້ອໂຄວິດ 19 ກ່ອນການສະໝັກປະກັນໄພ ແລະ ກ່ອນເຂົ້າ ສປປ ລາວ ຫຼື ໃນໄລຍະລໍຖ້າ.<br>
        12.2 ເງື່ອນໄຂທາງການແພດທີ່ມີຢູ່ກ່ອນວັນທີເລີ່ມຕົ້ນນະໂຍບາຍ.<br>
        12.3 ການຂ້າຕົວຕາຍ ຫຼື ພະຍາຍາມຂ້າຕົວຕາຍ ຫຼື ການບາດເຈັບຈາກການທຳຮ້າຍຕົນເອງ.<br>
        12.4 ສົງຄາມ (ບໍ່ວ່າຈະປະກາດ ຫຼື ບໍ່) ການບຸກລຸກ ຫຼື ການກະທຳຂອງສັດຕູຕ່າງປະເທດ, ສົງຄາມກາງເມືອງ, ການ ປະຕິວັດ,
        ການກະບົດ, ການໂຈມຕີຈະລາຈົນ ຫຼື ການກໍ່ຄວາມວຸ້ນວາຍຕໍ່ຕ້ານລັດຖະບານ.<br>
        12.5 ໃນຂະນະທີ່ຜູ້ປະກັນໄພ ຫຼື ຜູ້ທີ່ໄດ້ຮັບການຄຸ້ມຄອງເປັນສະມາຊິກຂອງບໍລິການປະກອບອາວຸດ, ຕໍາຫຼວດ ຫຼື ອາສາສະ ໝັກ
        ແລະ ມີສ່ວນຮ່ວມໃນສົງຄາມ ຫຼື ການສະກັດກັ້ນໃດໆ.<br>
        12.6 ອາວຸດນິວເຄລຍ, ນິວເຄລຍ ຟິວຊັນ, ຟິວຊັນ ຫຼື ມົນລະພິດລັງສີ.<br>
        12.7 ການບໍລິການທາງການແພດທີ່ກ່ຽວຂ້ອງກັບການປິ່ນປົວໂຣກພູມຕ້ານທານ (ເອດສ).<br>
        <p style="font-weight: bold; margin-top: 10px">13. ລາຍລະອຽດຂອງຜົນປະໂຫຍດ:</p>
        ຜົນປະໂຫຍດ ແມ່ນຕ້ອງຈ່າຍສໍາລັບຄ່າໃຊ້ຈ່າຍທາງການແພດທີ່ສົມເຫດສົມຜົນ ແລະ
        ມີຄວາມຈໍາເປັນທີ່ເກີດຂຶ້ນຈິງໃນໄລຍະເວລາຂອງການຄຸ້ມຄອງ.
        ບໍລິສັດຈະຈ່າຍຜົນປະໂຫຍດຂ້າງເທິງໃຫ້ຜູ້ປະກັນໄພຕາມຄວາມເປັນຈິງ.
    </div>`,
  },
};
